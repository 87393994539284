@import "./_var.scss";
body,
html {
    background-color: #000;
    color: #FFF;
    font-family: $gilroy!important;
    scroll-behavior: smooth;
}

.font-55 {
    font-family: $gilroy;
    font-weight: 1000;
    font-size: 55px;
    line-height: 150%;
    color: #EF6A6A;
    @media(max-width: $md1+px) {
        font-size: 40px;
    }
    @media(max-width: $md6+px) {
        font-size: 30px;
    }
}

.font-30 {
    font-family: $gilroy;
    font-style: normal;
    font-weight: 1000;
    font-size: 30px;
    line-height: 140%;
    color: #EF6A6A;
    @media(max-width: $md1+px) {
        font-size: 28px;
    }
    @media(max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-20 {
    font-family: $gilroy;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #fff;
    @media(max-width: $md1+px) {
        font-size: 18px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
}

.react-tel-input {
    background-color: transparent !important;
    height: 50px!important;
    width: 100%!important;
    border: 1px solid #29285E!important;
    @media(max-width: $md1+px) {
        height: 45px!important;
    }
    .flag-dropdown {
        border: none!important;
        &.open {
        }

        .selected-flag {
            .flag {
            }
            &.open {
                border: none;
            }
        }
        .selected-flag:hover {
            background-color: transparent !important;
        }
        .selected-flag:focus {
            background-color: transparent !important;
        }
    }
    .form-control {
        height: 100%!important;
        outline: none;
        width: 100%!important;
        border: none!important;
        background-color: transparent !important;
        font-family: $gilroy!important;
        font-weight: 500!important;
        font-size: 20px!important;
        line-height: 150%!important;
        color: #29285E!important;
        @media(max-width: $md1+px) {
            font-size: 18px!important;
        }
        @media(max-width: $md6+px) {
            font-size: 14px!important;
        }
        &::placeholder {
        }
    }
    .country-list {
        .country:hover {
        }
        .country.highlight {
        }
    }
}


.preloader {
    position: fixed;
    z-index: 999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 1;
    transition: all 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    &.hide {
      opacity: 0;
      pointer-events: none;
    }
}

.loader {
    width: 84px;
    height: 84px;
    position: relative;
    overflow: hidden;
    @media(max-width: $md6+px) {
        transform: scale(0.8);
    }
  }
  .loader:before , .loader:after {
    content: "";
    position: absolute;
    left: 50%;
    bottom: 0;
    width:64px;
    height: 64px;
    border-radius: 50%;
    background:#EF6A6A;
    transform: translate(-50% , 100%)  scale(0);
    animation: push 1s infinite ease-in;
  }
  .loader:after {
    animation-delay: .5s;
  }
  @keyframes push {
      0% {
        transform: translate(-50% , 100%)  scale(1);
      }
      15% , 25%{
        transform: translate(-50% , 50%)  scale(1);
      }
    50% , 75% {
        transform: translate(-50%, -30%) scale(0.5);
      }
    80%,  100% {
        transform: translate(-50%, -50%) scale(0);
      }
  }


.Dropdown-control {
    cursor: pointer!important;
    font-family: $gilroy!important;
    font-style: normal!important;
    font-weight: 500!important;
    font-size: 16px!important;
    background-color: transparent!important;
    border: none!important;
    color: #000000!important;
    text-transform: uppercase!important;
    padding-right: 30px!important;
    &:hover {
        box-shadow: none!important;
    }
}
.Dropdown-menu {
    font-family: $gilroy!important;
    font-style: normal;
    font-weight: 400!important;
    font-size: 16px!important;
    max-height: 300px!important;
}
.Dropdown-arrow {
    border-color: #2A2B2B transparent transparent!important;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #2A2B2B!important;
}

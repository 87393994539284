@font-face {
    font-family: "Gilroy";
    src: url("../assets/fonts/Gilroy-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: "Gilroy";
    src: url("../assets/fonts/Gilroy-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: "Gilroy";
    src: url("../assets/fonts/Gilroy-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: "Gilroy";
    src: url("../assets/fonts/Gilroy-Heavy.ttf") format("truetype");
    font-weight: 1000;
    font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&display=swap');
@import '../../styles/var';

.button {
    position: relative;
    display: inline-flex;
    background: #DA3A3A;
    border: 1px solid #DA3A3A;
    border-radius: 5px;
    padding: 13px 30px;
    font-family: $gilroy;
    font-weight: 800;
    font-size: 19px;
    line-height: 23px;
    color: #fff;
    text-align: center;
    cursor: pointer;
    transition: 0.3s ease-in-out;
    &:hover {
        // box-shadow: 0px 0px 0px 2.5px #fff, 0px 0px 0px 5px #29285E, 0px 0px 0px 10px white, 0px 0px 0px 10.5px #29285E;
        background: transparent;
        color: #DA3A3A;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
        line-height: 17px;
    }
}